import { getProgramFromPetInfoAndProducts } from '@app/shared/utils';
import { selectConsultationAllowanceParams } from '@app/store/consultation/consultation.selectors';
import { selectSelectedProducts } from '@app/store/products/products.selectors';
import { createSelector } from '@ngrx/store';

/**
 * Selector for current program
 */
export const selectAllowanceCurrentProgram = createSelector(
  selectSelectedProducts,
  selectConsultationAllowanceParams,
  (products, params) =>
    !!products &&
    !!params.deprecatedPetInfo &&
    getProgramFromPetInfoAndProducts(products, params.deprecatedPetInfo, params.followUpAction?.targetProgram)
);
